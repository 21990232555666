
export default {
  data () {
    return {
      // Layout data
      isMounted: false,
      isMenuOpen: false,
      headerActiveTab: null,
      activeTab: null,
      keyword: null,
      menuPrimaryItems: {
        Home: '/',
        'Programma\'s': {
          expand: true,
          children: {
            'BEAM Kerkdienst': '/programmas/eo-beam-kerkdienst',
            Denkstof: '/programmas/denkstof',
          },
        },
        'EO-Jongerendag': '/evenement/eojd-2024',
        Magazine: '/magazine',
        'BEAM-wear': this.$config.baseUrlMembership,
        Contact: '/contact',
      },
      menuSecondaryItems: {
        'BEAM Team': '/over-beam/team',
        'Over BEAM': '/over-beam',
        'Word lid': this.$config.baseUrlMembership,
        Stage: '/over-beam/stage',
        'Adverteren bij BEAM Magazine': '/adverteren',
      },
      menuSocialItems: {
        tiktok: 'https://www.tiktok.com/@eobeam',
        instagram: 'https://www.instagram.com/eobeam/',
        youtube: 'https://www.youtube.com/eobeam',
        whatsapp: 'https://wa.me/31615878111',
        spotify: 'https://open.spotify.com/user/eobeam/',
        facebook: 'https://www.facebook.com/jongeren/',
      },
      footerShortcuts: {
        'Ga naar...': {
          'Vraag & antwoord': '/over-beam/vraag-en-antwoord',
          Communityrichtlijnen: '/communityrichtlijnen-beam',
          'Over BEAM': '/over-beam',
          'Adverteren BEAM': '/adverteren',
          Contact: '/contact',
          Stage: '/over-beam/stage',
        },
      },
      footerSocials: {
        'Volg ons op...': {
          tiktok: {
            name: 'TikTok',
            path: 'https://www.tiktok.com/@eobeam',
          },
          instagram: {
            name: 'Instagram',
            path: 'https://www.instagram.com/eobeam/',
          },
          youtube: {
            name: 'Youtube',
            path: 'https://www.youtube.com/eobeam',
          },
          whatsapp: {
            name: 'Whatsapp',
            path: 'https://wa.me/31615878111',
          },
          spotify: {
            name: 'Spotify',
            path: 'https://open.spotify.com/user/eobeam/',
          },
          facebook: {
            name: 'Facebook',
            path: 'https://www.facebook.com/jongeren/',
          },
        },
      },
      // Campaign data
      articles: [],
      callToAction: null,
      changedOn: null,
      description: [],
      environmentId: null,
      faq: [],
      hero: null,
      heroVideo: null,
      heroVideoStill: null,
      id: null,
      intro: null,
      lineUp: [],
      publishOn: null,
      participate: [],
      seo: null,
      slug: null,
      social: null,
      terms: [],
      title: null,
      topic: null,
    }
  },
  async fetch () {
    // Get campaign for this slug
    const [
      { data: { items: campaigns = [] } = {} },
    ] = await Promise.all([
      this.$api.campaigns.getOneBySlug(this.$route.params.slug, {
        domain: this.$config.preprEnvironmentDomain,
      }),
    ])

    // Check publication if exists
    if (!campaigns || !campaigns.length) {
      return false
    }

    // Save campaig data
    this.callToAction = campaigns[0].callToAction
    this.changedOn = campaigns[0].changedOn
    this.description = campaigns[0].description
    this.environmentId = campaigns[0].environmentId
    this.faq = campaigns[0].faq
    this.hero = campaigns[0].hero
    this.heroVideo = campaigns[0].heroVideo
    this.heroVideoStill = campaigns[0].heroVideoStill
    this.id = campaigns[0].id
    this.intro = campaigns[0].intro
    this.lineUp = campaigns[0].lineUp
    this.publishOn = campaigns[0].publishOn
    this.participate = campaigns[0].participate
    this.seo = campaigns[0].seo
    this.slug = campaigns[0].slug
    this.social = campaigns[0].social
    this.terms = campaigns[0].terms
    this.title = campaigns[0].title
    this.topic = campaigns[0].topic

    // Get articles linked to this campaign
    const {
      data: {
        items: articles = [],
      } = {},
    } = await this.$api.articles.getAll({
      limit: 3,
      campaign: this.id,
    })
    this.articles = articles

    // Set breadcrumbs for the page
    this.$store.dispatch('breadcrumbs/set', {
      path: `/actie/${this.slug}`,
      eoRoot: true,
      floating: true,
      width: 10,
      items: [
        {
          to: '/',
          label: 'BEAM',
        },
        {
          label: this.title,
        },
      ],
    })
  },
  computed: {
    breadcrumbs () {
      return this.$store.getters['breadcrumbs/items'](this.pathWithoutTrailingSlash)
    },
    breadcrumbsWidth () {
      return this.$store.getters['breadcrumbs/width'](this.pathWithoutTrailingSlash)
    },
    isBreadcrumbsFloating () {
      return this.$store.getters['breadcrumbs/floating'](this.pathWithoutTrailingSlash)
    },
    hasBreadcrumbsEoRoot () {
      return this.$store.getters['breadcrumbs/eoRoot'](this.pathWithoutTrailingSlash)
    },
    pathWithoutTrailingSlash () {
      return this.$route.path.replace(/\/$/, '')
    },
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    onNavigate () {
      this.isMenuOpen = false
    },
    onSearch (keyword) {
      this.$router.push({
        path: '/zoeken',
        query: {
          invoer: keyword,
        },
      })
      this.isMenuOpen = false
      this.keyword = null
    },
    errorCaptured (error, vm, info) {
      this.$log.error({
        ...error,
        ...info,
      })
    },
  },
}
