import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3f49527c = () => interopDefault(import('../pages/doneren-bedrijven/index.vue' /* webpackChunkName: "pages/doneren-bedrijven/index" */))
const _dec3e7ce = () => interopDefault(import('../pages/link-in-bio.vue' /* webpackChunkName: "pages/link-in-bio" */))
const _05c02508 = () => interopDefault(import('../pages/programmas/index.vue' /* webpackChunkName: "pages/programmas/index" */))
const _32676224 = () => interopDefault(import('../pages/zoeken.vue' /* webpackChunkName: "pages/zoeken" */))
const _8c0f011e = () => interopDefault(import('../pages/over-beam/team/index.vue' /* webpackChunkName: "pages/over-beam/team/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _8f7c43ae = () => interopDefault(import('../pages/over-beam/team/_slug.vue' /* webpackChunkName: "pages/over-beam/team/_slug" */))
const _ea46239c = () => interopDefault(import('../pages/actie/_slug/index.vue' /* webpackChunkName: "pages/actie/_slug/index" */))
const _01bf4077 = () => interopDefault(import('../pages/alles-over/_slug.vue' /* webpackChunkName: "pages/alles-over/_slug" */))
const _3ec78eed = () => interopDefault(import('../pages/artikel/_slug.vue' /* webpackChunkName: "pages/artikel/_slug" */))
const _9f44db26 = () => interopDefault(import('../pages/evenement/_slug/index.vue' /* webpackChunkName: "pages/evenement/_slug/index" */))
const _040983c0 = () => interopDefault(import('../pages/programmas/_slug.vue' /* webpackChunkName: "pages/programmas/_slug" */))
const _abd78b7a = () => interopDefault(import('../pages/programmas/_slug/index.vue' /* webpackChunkName: "pages/programmas/_slug/index" */))
const _6b0fbb76 = () => interopDefault(import('../pages/programmas/_slug/afleveringen.vue' /* webpackChunkName: "pages/programmas/_slug/afleveringen" */))
const _c7acca7c = () => interopDefault(import('../pages/programmas/_slug/extras.vue' /* webpackChunkName: "pages/programmas/_slug/extras" */))
const _371b231a = () => interopDefault(import('../pages/programmas/_slug/over.vue' /* webpackChunkName: "pages/programmas/_slug/over" */))
const _6c38c4dd = () => interopDefault(import('../pages/rubriek/_slug.vue' /* webpackChunkName: "pages/rubriek/_slug" */))
const _4d3d9612 = () => interopDefault(import('../pages/actie/_slug/line-up/index.vue' /* webpackChunkName: "pages/actie/_slug/line-up/index" */))
const _69158c17 = () => interopDefault(import('../pages/actie/_slug/nieuws.vue' /* webpackChunkName: "pages/actie/_slug/nieuws" */))
const _43189cc2 = () => interopDefault(import('../pages/evenement/_slug/fundraising.vue' /* webpackChunkName: "pages/evenement/_slug/fundraising" */))
const _fc79239c = () => interopDefault(import('../pages/evenement/_slug/line-up/index.vue' /* webpackChunkName: "pages/evenement/_slug/line-up/index" */))
const _3d77d5e1 = () => interopDefault(import('../pages/evenement/_slug/livestream.vue' /* webpackChunkName: "pages/evenement/_slug/livestream" */))
const _18ad2188 = () => interopDefault(import('../pages/evenement/_slug/nieuws.vue' /* webpackChunkName: "pages/evenement/_slug/nieuws" */))
const _5e1a9459 = () => interopDefault(import('../pages/actie/_slug/line-up/_person.vue' /* webpackChunkName: "pages/actie/_slug/line-up/_person" */))
const _76d36ad4 = () => interopDefault(import('../pages/evenement/_slug/line-up/_person.vue' /* webpackChunkName: "pages/evenement/_slug/line-up/_person" */))
const _ccfa236a = () => interopDefault(import('../pages/actie/_slug/_field.vue' /* webpackChunkName: "pages/actie/_slug/_field" */))
const _2416d170 = () => interopDefault(import('../pages/evenement/_slug/_field.vue' /* webpackChunkName: "pages/evenement/_slug/_field" */))
const _2efaf336 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/doneren-bedrijven",
    component: _3f49527c,
    name: "doneren-bedrijven"
  }, {
    path: "/link-in-bio",
    component: _dec3e7ce,
    name: "link-in-bio"
  }, {
    path: "/programmas",
    component: _05c02508,
    name: "programmas"
  }, {
    path: "/zoeken",
    component: _32676224,
    name: "zoeken"
  }, {
    path: "/over-beam/team",
    component: _8c0f011e,
    name: "over-beam-team"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/over-beam/team/:slug?",
    component: _8f7c43ae,
    name: "over-beam-team-slug"
  }, {
    path: "/actie/:slug",
    component: _ea46239c,
    name: "actie-slug"
  }, {
    path: "/alles-over/:slug?",
    component: _01bf4077,
    name: "alles-over-slug"
  }, {
    path: "/artikel/:slug?",
    component: _3ec78eed,
    name: "artikel-slug"
  }, {
    path: "/evenement/:slug",
    component: _9f44db26,
    name: "evenement-slug"
  }, {
    path: "/programmas/:slug",
    component: _040983c0,
    children: [{
      path: "",
      component: _abd78b7a,
      name: "programmas-slug"
    }, {
      path: "afleveringen",
      component: _6b0fbb76,
      name: "programmas-slug-afleveringen"
    }, {
      path: "extras",
      component: _c7acca7c,
      name: "programmas-slug-extras"
    }, {
      path: "over",
      component: _371b231a,
      name: "programmas-slug-over"
    }]
  }, {
    path: "/rubriek/:slug?",
    component: _6c38c4dd,
    name: "rubriek-slug"
  }, {
    path: "/actie/:slug?/line-up",
    component: _4d3d9612,
    name: "actie-slug-line-up"
  }, {
    path: "/actie/:slug?/nieuws",
    component: _69158c17,
    name: "actie-slug-nieuws"
  }, {
    path: "/evenement/:slug?/fundraising",
    component: _43189cc2,
    name: "evenement-slug-fundraising"
  }, {
    path: "/evenement/:slug?/line-up",
    component: _fc79239c,
    name: "evenement-slug-line-up"
  }, {
    path: "/evenement/:slug?/livestream",
    component: _3d77d5e1,
    name: "evenement-slug-livestream"
  }, {
    path: "/evenement/:slug?/nieuws",
    component: _18ad2188,
    name: "evenement-slug-nieuws"
  }, {
    path: "/actie/:slug?/line-up/:person?",
    component: _5e1a9459,
    name: "actie-slug-line-up-person"
  }, {
    path: "/evenement/:slug?/line-up/:person?",
    component: _76d36ad4,
    name: "evenement-slug-line-up-person"
  }, {
    path: "/actie/:slug?/:field",
    component: _ccfa236a,
    name: "actie-slug-field"
  }, {
    path: "/evenement/:slug?/:field",
    component: _2416d170,
    name: "evenement-slug-field"
  }, {
    path: "/*",
    component: _2efaf336,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
